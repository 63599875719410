<template>
  <div class="oscar-column">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.OscarColumn"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb location="/oscar-columns" text="Colonne OSCAR"></breadcrumb>
          {{ oscarColumn.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <span>Informations</span>
        </div>
        <div class="panel-block is-block no-border">
          <div class="columns">
            <div class="column is-half" v-if="oscarColumn">
              <text-field
                label="Nom"
                v-model="oscarColumn.name"
                required
              ></text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "oscar-column",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
  },
  data() {
    return {
      oscarColumn: {},
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      axios.get(`/oscar-columns/${this.id}`).then((r) => {
        this.oscarColumn = r.data;
        this.$store.dispatch("App/setEditing", false);
      });
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios
            .delete(`/oscar-columns/${this.id}`)
            .then((res) => {
              if (res.data.success) {
                this.$router.replace("/oscar-columns");
              }
            }),
      );
    },
    save() {
      return axios
        .put(`/oscar-columns/${this.id}`, this.oscarColumn)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
  },
};
</script>

<style lang='scss' scoped>
</style>